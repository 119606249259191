
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _400bZQ9wmyZFZMeta } from "/usr/src/nuxt-app/pages/400.vue?macro=true";
import { default as _404Am7IRVzZLlMeta } from "/usr/src/nuxt-app/pages/404.vue?macro=true";
import { default as _500G6FYxrlp1HMeta } from "/usr/src/nuxt-app/pages/500.vue?macro=true";
import { default as aboutqNk3HSzUMDMeta } from "/usr/src/nuxt-app/pages/about.vue?macro=true";
import { default as contactys44YCxHo7Meta } from "/usr/src/nuxt-app/pages/contact.vue?macro=true";
import { default as indexAlKKXJi8jwMeta } from "/usr/src/nuxt-app/pages/courses/[id]/index.vue?macro=true";
import { default as helpyMZjyZoaSzMeta } from "/usr/src/nuxt-app/pages/help.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as indexlNSqv3aNHdMeta } from "/usr/src/nuxt-app/pages/kategori/[serial]/index.vue?macro=true";
import { default as indexFr9SG4UmJ0Meta } from "/usr/src/nuxt-app/pages/kategori/index.vue?macro=true";
import { default as kebijakan_45privasi3YppW17TppMeta } from "/usr/src/nuxt-app/pages/kebijakan-privasi.vue?macro=true";
import { default as ketentuan_45layanan6SwS27EAqWMeta } from "/usr/src/nuxt-app/pages/ketentuan-layanan.vue?macro=true";
import { default as certificatetsPIFKFNHpMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/certificate.vue?macro=true";
import { default as indexnaUqBzHvFQMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/index.vue?macro=true";
import { default as materiEUAUJnQxBWMeta } from "/usr/src/nuxt-app/pages/kursus/[slug]/materi.vue?macro=true";
import { default as indexyhqV3wQ1DrMeta } from "/usr/src/nuxt-app/pages/kursus/index.vue?macro=true";
import { default as indexEuoGle1vruMeta } from "/usr/src/nuxt-app/pages/partnerplaylist/[slug]/index.vue?macro=true";
import { default as certificatewNxdrYB7hkMeta } from "/usr/src/nuxt-app/pages/partnerplaylist/[slug]/kursus/[slug-kursus]/certificate.vue?macro=true";
import { default as indexu2HDDCrMDsMeta } from "/usr/src/nuxt-app/pages/partnerplaylist/[slug]/kursus/[slug-kursus]/index.vue?macro=true";
import { default as materiKL8gEO5rhKMeta } from "/usr/src/nuxt-app/pages/partnerplaylist/[slug]/kursus/[slug-kursus]/materi.vue?macro=true";
import { default as activatew3Wlxl4NT1Meta } from "/usr/src/nuxt-app/pages/partnerplaylist/activate.vue?macro=true";
import { default as indexTd7uX54SPMMeta } from "/usr/src/nuxt-app/pages/partnerplaylist/index.vue?macro=true";
import { default as joinFGp9e0ZN6DMeta } from "/usr/src/nuxt-app/pages/partnerplaylist/join.vue?macro=true";
import { default as pusat_45bantuanjieEWTPEjWMeta } from "/usr/src/nuxt-app/pages/pusat-bantuan.vue?macro=true";
import { default as reset_45emailsjv5ea7BiMMeta } from "/usr/src/nuxt-app/pages/reset-email.vue?macro=true";
import { default as indexgceGhax48pMeta } from "/usr/src/nuxt-app/pages/reset-password/index.vue?macro=true";
import { default as success2TR5GAVatAMeta } from "/usr/src/nuxt-app/pages/reset-password/success.vue?macro=true";
import { default as searchiOAa32tjZNMeta } from "/usr/src/nuxt-app/pages/search.vue?macro=true";
import { default as signindVFe89Mb1OMeta } from "/usr/src/nuxt-app/pages/signin.vue?macro=true";
import { default as signuppd0Tj8SUdWMeta } from "/usr/src/nuxt-app/pages/signup.vue?macro=true";
import { default as indexQqM7wRBM9uMeta } from "/usr/src/nuxt-app/pages/user/form/index.vue?macro=true";
import { default as index9Y8cICqHnwMeta } from "/usr/src/nuxt-app/pages/user/index.vue?macro=true";
import { default as indexO9w7m01WkLMeta } from "/usr/src/nuxt-app/pages/user/riwayat-transaksi/index.vue?macro=true";
import { default as indexik8LrhTwdQMeta } from "/usr/src/nuxt-app/pages/user/studi-saya/index.vue?macro=true";
import { default as indexvvVKYgfoNEMeta } from "/usr/src/nuxt-app/pages/user/wishlist/index.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/usr/src/nuxt-app/pages/400.vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/usr/src/nuxt-app/pages/404.vue")
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/usr/src/nuxt-app/pages/500.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/usr/src/nuxt-app/pages/about.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/usr/src/nuxt-app/pages/contact.vue")
  },
  {
    name: "courses-id",
    path: "/courses/:id()",
    meta: indexAlKKXJi8jwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/courses/[id]/index.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/usr/src/nuxt-app/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "kategori-serial",
    path: "/kategori/:serial()",
    component: () => import("/usr/src/nuxt-app/pages/kategori/[serial]/index.vue")
  },
  {
    name: "kategori",
    path: "/kategori",
    component: () => import("/usr/src/nuxt-app/pages/kategori/index.vue")
  },
  {
    name: "kebijakan-privasi",
    path: "/kebijakan-privasi",
    component: () => import("/usr/src/nuxt-app/pages/kebijakan-privasi.vue")
  },
  {
    name: "ketentuan-layanan",
    path: "/ketentuan-layanan",
    component: () => import("/usr/src/nuxt-app/pages/ketentuan-layanan.vue")
  },
  {
    name: "kursus-slug-certificate",
    path: "/kursus/:slug()/certificate",
    meta: certificatetsPIFKFNHpMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/certificate.vue")
  },
  {
    name: "kursus-slug",
    path: "/kursus/:slug()",
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/index.vue")
  },
  {
    name: "kursus-slug-materi",
    path: "/kursus/:slug()/materi",
    meta: materiEUAUJnQxBWMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/kursus/[slug]/materi.vue")
  },
  {
    name: "kursus",
    path: "/kursus",
    component: () => import("/usr/src/nuxt-app/pages/kursus/index.vue")
  },
  {
    name: "partnerplaylist-slug",
    path: "/partnerplaylist/:slug()",
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/[slug]/index.vue")
  },
  {
    name: "partnerplaylist-slug-kursus-slugkursus-certificate",
    path: "/partnerplaylist/:slug()/kursus/:slugkursus()/certificate",
    meta: certificatewNxdrYB7hkMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/[slug]/kursus/[slug-kursus]/certificate.vue")
  },
  {
    name: "partnerplaylist-slug-kursus-slugkursus",
    path: "/partnerplaylist/:slug()/kursus/:slugkursus()",
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/[slug]/kursus/[slug-kursus]/index.vue")
  },
  {
    name: "partnerplaylist-slug-kursus-slugkursus-materi",
    path: "/partnerplaylist/:slug()/kursus/:slugkursus()/materi",
    meta: materiKL8gEO5rhKMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/[slug]/kursus/[slug-kursus]/materi.vue")
  },
  {
    name: "partnerplaylist-activate",
    path: "/partnerplaylist/activate",
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/activate.vue")
  },
  {
    name: "partnerplaylist",
    path: "/partnerplaylist",
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/index.vue")
  },
  {
    name: "partnerplaylist-join",
    path: "/partnerplaylist/join",
    component: () => import("/usr/src/nuxt-app/pages/partnerplaylist/join.vue")
  },
  {
    name: "pusat-bantuan",
    path: "/pusat-bantuan",
    component: () => import("/usr/src/nuxt-app/pages/pusat-bantuan.vue")
  },
  {
    name: "reset-email",
    path: "/reset-email",
    component: () => import("/usr/src/nuxt-app/pages/reset-email.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/usr/src/nuxt-app/pages/reset-password/index.vue")
  },
  {
    name: "reset-password-success",
    path: "/reset-password/success",
    component: () => import("/usr/src/nuxt-app/pages/reset-password/success.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/usr/src/nuxt-app/pages/search.vue")
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/usr/src/nuxt-app/pages/signin.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/usr/src/nuxt-app/pages/signup.vue")
  },
  {
    name: "user-form",
    path: "/user/form",
    meta: indexQqM7wRBM9uMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/form/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: index9Y8cICqHnwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/index.vue")
  },
  {
    name: "user-riwayat-transaksi",
    path: "/user/riwayat-transaksi",
    meta: indexO9w7m01WkLMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/riwayat-transaksi/index.vue")
  },
  {
    name: "user-studi-saya",
    path: "/user/studi-saya",
    meta: indexik8LrhTwdQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/studi-saya/index.vue")
  },
  {
    name: "user-wishlist",
    path: "/user/wishlist",
    meta: indexvvVKYgfoNEMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/wishlist/index.vue")
  }
]