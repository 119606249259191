import { useMessageStore } from '../message'
import {
  type AuthState,
  type DataLoginSSOType,
  type DataLoginType,
  type DataRegisterType,
  type DataRequestPasswordResetType,
  type DataResetPasswordType
} from './types'
import { type ActionParams } from 'types/storeTypes'

const useActions = (state: AuthState) => {
  const { handleErrorInfo } = useCustomFetch()
  const messageStore = useMessageStore()
  const authStorage = useAuthStorage()

  const { API_URL } = useBaseUrl()

  const login = async ({
    payload,
    resolve = () => null,
    preload = () => null
  }: ActionParams<DataLoginType>) => {
    preload(true)
    state.loading = true
    try {
      const res = await $fetch(`${API_URL}/login`, {
        method: 'POST',
        body: payload
      })

      const data = useResult(res, 'data', null)
      const token = data?.token
      const email = data?.email
      const { setCookies } = useCookiesServices()
      setCookies(data)

      state.isLoggedIn = !!token
      state.user = data
      authStorage.store(token, data, email)

      resolve()
    } catch (error: any) {
      messageStore.notifyStdError(error?.data?.message)
    } finally {
      state.loading = false
      preload(false)
    }
  }
  const loginSSO = async ({
    payload,
    resolve = () => null
  }: ActionParams<DataLoginSSOType>) => {
    const { data, error, pending } = await useAsyncData('loginSSo', () =>
      $fetch(`${API_URL}/sso`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      handleErrorInfo(error, 'loginSSO')
    }

    const loggedinData = useResult(data?.value, 'data', null)
    const token = loggedinData?.token
    const email = loggedinData?.email

    state.isLoggedIn = !!token
    state.user = loggedinData
    authStorage.store(token, loggedinData, email)
    resolve()
  }

  const register = async ({
    payload,
    resolve = () => null
  }: ActionParams<DataRegisterType>) => {
    const { data, error, pending } = await useAsyncData('register', () =>
      $fetch(`${API_URL}/register`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      handleErrorInfo(error, 'register')
    }

    const loggedinData = useResult(data?.value, 'data', null)
    const token = loggedinData?.token
    const email = loggedinData?.email

    state.isLoggedIn = !!token
    state.user = loggedinData
    authStorage.store(token, loggedinData, email)
    resolve()
  }

  const requestPasswordReset = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<DataRequestPasswordResetType>) => {
    const { data, error, pending } = await useAsyncData('resetPassword', () =>
      $fetch(`${API_URL}/reset-password`, {
        method: 'POST',
        body: payload
      })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      const code = useResult(error.value, 'data.message', 0)
      messageStore.notifyStdError(code)
      reject()
      return
    }

    resolve()
  }

  const resetPassword = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<DataResetPasswordType>) => {
    const { data, error, pending } = await useAsyncData(
      'updateUserPassword',
      () =>
        $fetch(`${API_URL}/update-user-password`, {
          method: 'POST',
          body: payload
        })
    )

    state.loading = pending.value
    if (!data.value && error.value) {
      const code = useResult(error.value, 'data.message', 0)
      messageStore.notifyStdError(code)
      reject()
      return
    }

    resolve()
  }
  const logout = (destination: string = '/') => {
    const router = useRouter()
    authStorage.clear()

    state.isLoggedIn = false
    state.user = null

    router.push(destination).then(() => {
      if (process.client) {
        window.location.reload()
      }
    })
  }

  const getTitle = (titleChunk: string = '') => {
    return titleChunk ? `Kognisi.id | ${titleChunk}` : state.meta?.title
  }

  const getDescription = (description: string = '') => {
    return description || state.meta?.description
  }

  return {
    login,
    loginSSO,
    register,
    requestPasswordReset,
    resetPassword,
    logout,
    getTitle,
    getDescription
  }
}

export default useActions
