const StdErrorMapping = {
  // Authentication errors
  sso_user_invalid_action:
    'User terdaftar melalui mekanisme lain (Google/Facebook SSO)',
  user_already_registered: 'Email sudah terdaftar',
  user_email_not_found: 'Email tidak terdaftar',
  invalid_password_pattern: 'Perubahan gagal disimpan',
  password_did_not_match: 'Perubahan gagal disimpan',
  'invalid email or password error': 'Email atau password tidak valid',
  'already enrolled': 'User sudah terdaftar di kursus ini',
  'voucher cannot be redeemed now': 'Voucher tidak dapat ditukarkan sekarang',
  'voucher is not applicable for this course':
    'Voucher tidak berlaku untuk kursus ini',
  'max total redemption has been exceeded':
    'Maksimum total penukaran telah melebihi batas',
  'voucher is not applicable for this user': 'Voucher tidak berlaku untuk kamu',
  incomplete_content_accomplishment:
    'Masih ada materi yang belum selesai, silahkan check kembali.',
  'record not found': 'Kode voucher yang dimasukkan tidak ditemukan',
  'this user has exceeded the max redemption allowed':
    'Kamu telah mencapai limit penggunaan voucher',
  // other errors
  reset_password_request_rate_limited:
    'Email telah dikirim. Silakan periksa email Anda atau coba lagi dalam beberapa menit untuk pengiriman ulang.',
  reset_password_token_invalid: 'Link reset password sudah tidak valid',
  401: 'Kamu tidak mempunyai akses',
  404: 'API endpoint tidak ditemukan',
  'user not enrolled to this partner group':
    'Kamu tidak terdaftar pada grup mitra ini',
  default: 'Terjadi kesalahan, silakan hubungi administrator'
}

export default StdErrorMapping
