import { useMessageStore } from '~~/stores/message'
import { useAuthStore } from '~~/stores/auth'

export const useCustomFetch = () => {
  const messageStore = useMessageStore()

  const handleErrorInfo = (error: any, name: string) => {
    const authStore = useAuthStore()
    const unauthenticatedCode: number[] = [401, 403]
    const statusCode: number = useResult(error.value, 'statusCode', 0)
    messageStore.notifyStdError(statusCode)

    if (unauthenticatedCode.includes(statusCode)) {
      authStore.logout('/signin')
    }

    throw createError({
      statusCode,
      statusMessage: `${name}: ${error.value?.message}`
    })
  }
  return {
    handleErrorInfo
  }
}
